import { downloadFromLink } from 'utils/utility/utils';
import { OperatorEnum } from 'components/ShareCatalogList/constants';
import {
  KEYS_CONTAINING_BU_HEAD,
  KEYS_CONTAINING_CATEGORY_MANAGER,
  KEYS_CONTAINING_DESIGNER,
  KEYS_CONTAINING_SALES_REPRESENTATIVE,
} from 'components/CatalogViewer/constants';
import {
  IShareableData,
  ISharedCatalog,
} from 'components/ShareCatalogList/types';
import {
  IGetEachUserObject,
  IGetLocationData,
  IGetQrCodeResponse,
  IGetResponse,
  ISendLocationData,
  ISendQrCode,
} from 'components/AddCatalogList/types';
import {
  ICollection,
  ICollectionResult,
  IProductsData,
} from 'components/Collections/types';
import { ApiResponseEnum } from 'customTypes/callbackActionTypes';
import { DEFAULT_VALUE } from 'utils/defaults';
import apiResponseHandler from './apiResponseHandler';
import Toast from '../library/Toast';
import CATALOG_ACTION_TYPES from '../actionTypes/catalog';
import {
  IGetEachProductDetails,
  IUplaodImageType,
} from 'components/AddCatalogList/ImageUpdation/types';
import { openInNewTab } from '../utils/helpers';
import {
  bulkProductStatusUpdateApi,
  getAllAdminsApi,
  getAllCategoriesApi,
  getAllSharedCatalogsApi,
  getExcelTemplateCategoriesApi,
  getExcelTemplateDownloadLinkApi,
  getSuppliersApi,
  updateSharedCatalogDetails,
  updateSharedCatalogProducts,
  uploadCatalogueSheetLinkApi,
  uploadUpdatedCatalogSheetApi,
  generateShareableLink,
  getMaterialsApi,
  getAdminCatalogProductsApi,
  getTrendingProductsForCategoryApi,
  putTrendingProductsForCategoryDataApi,
  getBrandsApi,
  createCollectionFromSharedCatalogApi,
  getAllCollectionsApi,
  deleteCollectionApi,
  updateCollectionApi,
  getAllAdminCatalogProductsApi,
  getLocationRegionDataApi,
  updateLocationRegionDataApi,
  getCmAndSaleRepDataApi,
  getQrCodeApi,
  updateImagesApi,
  bulkEditExcelDownloadApi,
  getDefaultExcelTemplateApi,
  uploadDefaultCatalogueSheetLinkApi,
} from '../utils/api';
import {
  BulkOperationEnum,
  STANDARD_PAGE_SIZE,
  MAXIMUM_PAGE_SIZE,
} from '../utils/constants';
import {
  IBrand,
  IImage,
  IReduxActionType,
  IRootState,
  ThunkActionType,
} from '../utils/types';

const fetchCatalogsRequest = (payload: boolean): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_CATALOGS_REQUEST,
  payload,
});

export const fetchCatalogsSuccess = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_CATALOGS_SUCCESS,
  payload,
});

export const fetchDownloadLoader = (payload: boolean): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_DOWNLOAD_ALL_PRODUCT,
  payload,
});

export const setPaginationInfo = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_PAGINATION_INFO,
  payload,
});

const getNextPageCount = (
  currentCount: number,
  count: number,
  totalPages: number,
  pageSize: number | null
) => {
  // if not fetched yet, fetch first page
  if (totalPages === -1) return 0;

  const PAGE_SIZE = pageSize || STANDARD_PAGE_SIZE;
  const newPage = Math.ceil(Number(currentCount) / PAGE_SIZE);
  return newPage <= totalPages ? newPage : -1;
};

export const fetchCatalogsThunk =
  (query: string) =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    const { paginationInfo, list, catalogId } = getState().catalog;

    const newPage = getNextPageCount(
      list.length,
      paginationInfo.count,
      paginationInfo.totalPages,
      null
    );

    if (newPage === -1) {
      return; // end of page
    }

    dispatch(fetchCatalogsRequest(true)); // sets loader
    try {
      const response = await getAdminCatalogProductsApi(
        query,
        newPage,
        catalogId
      );
      if (!response.status) {
        throw new Error(response.msg);
      }

      const { meta, total_pages, products, secondary_filters, filters } =
        response.data;
      dispatch({
        ...fetchCatalogsSuccess(products),
        meta: { operation: 'APPEND' },
      });
      dispatch(
        setPaginationInfo({
          count: meta,
          totalPages: total_pages,
          currentPage: newPage,
        })
      );
      dispatch(setSecondaryFiltersInfo(secondary_filters));
      dispatch(setPrimaryFilters(filters));
      dispatch(fetchCatalogsRequest(false));
    } catch (error) {
      dispatch(fetchCatalogsRequest(false));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

const setPrimaryFilters = (primaryFilters: Array<any>): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_PRIMARY_FILTERS,
  payload: primaryFilters,
});

const fetchExcelTemplateCategoriesRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_REQUEST,
});

export const setSecondaryFiltersInfo = (
  payload: Array<any>
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_SECONDARY_FILTERS_INFO,
  payload,
});

export const setSelectedSecondaryFilterInfo = (
  secondaryFilterSelectedTag: string,
  secondaryFilterSelectedTagOptions: Array<any>
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_SECONDARY_SELECTED_FILTERS_INFO,
  payload: {
    secondaryFilterSelectedTag,
    secondaryFilterSelectedTagOptions,
  },
});

export const setCatalogId = (catalogId: string) => ({
  type: CATALOG_ACTION_TYPES.SET_CATALOG_ID,
  payload: {
    catalogId,
  },
});

export const setTabId = (tabId: string) => ({
  type: CATALOG_ACTION_TYPES.SET_TAB_ID,
  payload: {
    tabId,
  },
});

export const setSelectedFilters = (
  selectedFilters: Record<string, Array<any>>,
  shouldReset: boolean = false
) => ({
  type: CATALOG_ACTION_TYPES.SET_SELECTED_FILTERS,
  payload: {
    selectedFilters,
    shouldReset,
  },
});

const fetchExcelTemplateCategoriesSuccess = (
  payload: any
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_SUCCESS,
  payload,
});

const fetchExcelTemplateCategoriesFailure = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_CATEGORIES_FAILURE,
});

export const fetchExcelTemplateCategoriesThunk =
  (id: number | string) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(fetchExcelTemplateCategoriesRequest());
    try {
      const response = await getExcelTemplateCategoriesApi(id);
      if (!response.status) {
        dispatch(fetchExcelTemplateCategoriesFailure());
      }
      dispatch(fetchExcelTemplateCategoriesSuccess(response.data));
    } catch (err) {
      Toast.ERROR('Something went wrong');
      dispatch(fetchExcelTemplateCategoriesFailure());
    }
  };

const fetchExcelTemplateDownloadLinkRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_REQUEST,
});

const fetchExcelTemplateDownloadLinkSuccess = (
  payload: any
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_SUCCESS,
  payload,
});

const fetchExcelTemplateDownloadLinkFailure = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_EXCEL_TEMPLATE_DOWNLOAD_LINK_FAILURE,
});

export const fetchExcelTemplateDownloadLinkThunk =
  (id: number | string) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(fetchExcelTemplateDownloadLinkRequest());
    try {
      const response = await getExcelTemplateDownloadLinkApi(id);
      if (!response.status) {
        dispatch(fetchExcelTemplateDownloadLinkFailure());
        Toast.ERROR(response.msg);
        return;
      }
      openInNewTab(response.data.sheet_url);
      dispatch(fetchExcelTemplateDownloadLinkSuccess(response.data.sheet_url));
    } catch (err) {
      dispatch(fetchExcelTemplateDownloadLinkFailure());
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

export const fetchDefaultExcelTemplateDownloadLinkThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    // dispatch(fetchExcelTemplateDownloadLinkRequest());
    try {
      const queryParams = 'default_template=true';
      const response = await getDefaultExcelTemplateApi();
      if (!response.status) {
        // dispatch(fetchExcelTemplateDownloadLinkFailure());
        Toast.ERROR(response.msg);
        return;
      }
      openInNewTab(response.data.sheet_url);
      // dispatch(
      //   fetchExcelTemplateDownloadLinkSuccess(response.data.sheet_url)
      // );
    } catch (err) {
      dispatch(fetchExcelTemplateDownloadLinkFailure());
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

export const uploadCatalogueSheetRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_REQUEST,
});

export const uploadCatalogueSheetFailure = (
  payload: string
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_FAILURE,
  payload,
});

const uploadCatalogueSheetSuccess = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_SUCCESS,
});

export const uploadCatalogueSheetIdle = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPLOAD_CATALOGUE_SHEET_IDLE,
});

export const uploadCatalogueSheetThunk =
  (
    region: Array<number>,
    designer: number,
    id: number | string,
    data: string
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await uploadCatalogueSheetLinkApi({
        sheet_url: data,
        supplier_id: id,
        region_ids: region,
        designer_id: designer,
      });
      if (!response.status) {
        dispatch(uploadCatalogueSheetFailure(response.msg));
        return;
      }

      dispatch(uploadCatalogueSheetSuccess());
    } catch (err) {
      dispatch(uploadCatalogueSheetFailure(''));
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

export const uploadDefaultCatalogueSheetThunk =
  (
    region: Array<number>,
    designer: number,
    id: number | string,
    data: string
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await uploadDefaultCatalogueSheetLinkApi({
        sheet_url: data,
        supplier_id: id,
        region_ids: region,
        designer_id: designer,
      });
      if (!response.status) {
        dispatch(uploadCatalogueSheetFailure(response.msg));
        return;
      }

      dispatch(uploadCatalogueSheetSuccess());
    } catch (err) {
      dispatch(uploadCatalogueSheetFailure(''));
      if (err instanceof Error) {
        Toast.ERROR(err.message);
      }
    }
  };

export const addProductIds = (
  payload: Array<string>
): IReduxActionType<Array<string>> => ({
  type: CATALOG_ACTION_TYPES.ADD_SELECTED_PRODUCT_IDS,
  payload,
});

export const removeProductIds = (
  payload: Array<string>
): IReduxActionType<Array<string>> => ({
  type: CATALOG_ACTION_TYPES.REMOVE_SELECTED_PRODUCT_IDS,
  payload,
});

const toggleIsApproveButtonLoading = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_IS_APPROVE_BUTTON_LOADING,
  payload,
});

const toggleIsSetInactiveButtonLoading = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_IS_SET_INACTIVE_BUTTON_LOADING,
  payload,
});

const toggleIsArchiveButtonLoading = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_IS_ARCHIVE_BUTTON_LOADING,
  payload,
});

const toggleIsActiveButtonLoading = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_IS_ACTIVE_BUTTON_LOADING,
  payload,
});

export const updateStatusOfIndividualItems = (meta: {
  operation: BulkOperationEnum;
}): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPDATE_STATUS_OF_INDIVIDUAL_ITEMS,
  meta,
});

/*
 * whenever items are being set to INACTIVE or are APPROVED
 * ideally they must be be updated in UI from an API response
 * and not the way it is being done as below
 */
// export const fetchAndSetIndividualItemsInList =
//   (operation: BulkOperationEnum) =>
//   async (
//     dispatch: (action: IReduxActionType) => void,
//     getState: () => IRootState
//   ) => {
//     return updateStatusOfIndividualItems({ operation });
//   };

export const setSelectedProductsAsArchived = () => ({
  type: CATALOG_ACTION_TYPES.SET_SELECTED_PRODUCTS_AS_ARCHIVED,
});

export const setInactivateBulkProductsThunk =
  () =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    dispatch(toggleIsSetInactiveButtonLoading(true));

    try {
      const { selectedProductIds } = getState().catalog;

      const data = {
        product_ids: Array.from(selectedProductIds),
        action: 'inactive',
      };

      const response = await bulkProductStatusUpdateApi(data);

      dispatch(toggleIsSetInactiveButtonLoading(false));
      const { status, msg: message } = response;

      if (status) {
        Toast.SUCCESS(message);
        dispatch(
          updateStatusOfIndividualItems({
            operation: BulkOperationEnum.SET_INACTIVE,
          })
        );
      } else {
        Toast.ERROR(message);
      }
    } catch (error) {
      dispatch(toggleIsSetInactiveButtonLoading(false));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const archiveBulkProductsThunk =
  () =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    dispatch(toggleIsArchiveButtonLoading(true));

    try {
      const { selectedProductIds } = getState().catalog;

      const data = {
        product_ids: Array.from(selectedProductIds),
        action: 'archive',
      };

      const response = await bulkProductStatusUpdateApi(data);
      const { status, msg: message } = response;
      if (status) {
        Toast.SUCCESS(message);
        dispatch(setSelectedProductsAsArchived());
      } else {
        Toast.ERROR(message);
      }
      dispatch(toggleIsArchiveButtonLoading(false));
    } catch (error) {
      dispatch(toggleIsArchiveButtonLoading(false));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const approveBulkProductsThunk =
  () =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    dispatch(toggleIsApproveButtonLoading(true));

    try {
      const { selectedProductIds } = getState().catalog;

      const data = {
        product_ids: Array.from(selectedProductIds),
        action: 'approve',
      };

      const response = await bulkProductStatusUpdateApi(data);
      const { status, msg: message } = response;
      if (status) {
        Toast.SUCCESS(message);
        dispatch(
          updateStatusOfIndividualItems({
            operation: BulkOperationEnum.SET_APPROVED,
          })
        );
      } else {
        Toast.ERROR(message);
      }

      dispatch(toggleIsApproveButtonLoading(false));
    } catch (error) {
      dispatch(toggleIsApproveButtonLoading(false));

      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const activeBulkProductsThunk =
  () =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    dispatch(toggleIsActiveButtonLoading(true));

    try {
      const { selectedProductIds } = getState().catalog;

      const data = {
        product_ids: Array.from(selectedProductIds),
        action: 'active',
      };

      const response = await bulkProductStatusUpdateApi(data);
      const { status, msg: message } = response;
      if (status) {
        Toast.SUCCESS(message);
        dispatch(
          updateStatusOfIndividualItems({
            operation: BulkOperationEnum.SET_ACTIVE,
          })
        );
      } else {
        Toast.ERROR(message);
      }

      dispatch(toggleIsActiveButtonLoading(false));
    } catch (error) {
      dispatch(toggleIsActiveButtonLoading(false));

      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const uploadUpdatedCatalogSheetThunk =
  (data: any) => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setIsUpdatingSheetUrl(true));

    try {
      const response = await uploadUpdatedCatalogSheetApi(data);
      dispatch(setIsUpdatingSheetUrl(false));
      dispatch(setUploadedSheetUrl(''));
      if (response.status) {
        dispatch(setPaginationInfo({ count: 0, totalPages: -1 }));
      }
      return [response.status, response.msg] as const;
    } catch (error) {
      dispatch(setIsUpdatingSheetUrl(false));
      dispatch(setUploadedSheetUrl(''));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const setIsUpdatingSheetUrl = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_IS_UPDATING_SHEET_URL,
  payload,
});

export const setUploadedSheetUrl = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_UPLOADED_SHEET_URL,
  payload,
});

const fetchCategoriesRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_REQUEST,
});

const fetchCategoriesSuccess = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_SUCCESS,
  payload,
});

export const setUploadModalVisbility = (
  payload: boolean
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_UPLOAD_MODAL_VISIBILITY,
  payload,
});

const fetchCategoriesFailure = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_ALL_CATEGORIES_FAILURE,
});

export const fetchCategoriesThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(fetchCategoriesRequest());
    try {
      const response = await getAllCategoriesApi();

      if (response.status) {
        const payload = [...response.data];
        dispatch(fetchCategoriesSuccess(payload));
      } else {
        dispatch(fetchCategoriesFailure());
      }
    } catch (error) {
      dispatch(fetchCategoriesFailure());
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

const fetchSuppliersRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_REQUEST,
});

const fetchSuppliersSuccess = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_SUCCESS,
  payload,
});

const fetchSuppliersFailure = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_SUPPLIERS_SUCCESS,
});

const fetchMaterialsRequest = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_MATERIALS_REQUEST,
});

const fetchMaterialsSuccess = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_MATERIALS_SUCCESS,
  payload,
});

const fetchMaterialsFailure = (): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_MATERIALS_FAILURE,
});

export const fetchSuppliersThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(fetchSuppliersRequest());

    try {
      const response = await getSuppliersApi();

      if (response.status) {
        const { supplier } = response.data;

        dispatch(fetchSuppliersSuccess(supplier));
      } else {
        dispatch(fetchSuppliersFailure());
      }
    } catch (error) {
      dispatch(fetchSuppliersFailure());
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

const setBrandsData = (payload: Array<IBrand>) => {
  return {
    type: CATALOG_ACTION_TYPES.SET_BRANDS_DATA,
    payload,
  };
};

export const fetchBrandsThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getBrandsApi();
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setBrandsData(response.data.brand));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const fetchMaterialsThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(fetchMaterialsRequest());

    try {
      const response = await getMaterialsApi();

      if (response.status) {
        const payload = response.data;

        dispatch(fetchMaterialsSuccess(payload));
      } else {
        dispatch(fetchMaterialsFailure());
      }
    } catch (error) {
      dispatch(fetchMaterialsFailure());
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const shareableLinkGenerate = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SHAREABLE_LINK,
  payload,
});

export const setShareableCatalogsCount = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_SHAREABLE_CATALOGS_COUNT,
  payload,
});

export const setAllAdmins = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_ALL_ADMINS,
  payload,
});

export const shareableLinkThunk =
  (
    selectedProductIds: Array<string>,
    catalogDetails: {
      imageUrl: string;
    },
    callback: (validCount: number, linkIdentifier: string) => void
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await generateShareableLink({
        products: selectedProductIds,
        catalog_details: {
          image_url: catalogDetails.imageUrl,
        },
      });
      if (response.status) {
        dispatch(shareableLinkGenerate(response.data.url));
        callback(
          response.data.valid_products_count,
          response.data.link_identifier
        );
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      dispatch(shareableLinkGenerate(''));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const getAllShareableCatalogs = (
  payload: any,
  meta?: any
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.GET_ALL_SHAREABLE_CATALOGS,
  payload,
  meta,
});

export const getAllAdminsThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    try {
      const response = await getAllAdminsApi();
      if (response.status) {
        dispatch(setAllAdmins(response.data));
      } else {
        Toast.ERROR("Couldn't fetch admins");
      }
    } catch {
      Toast.ERROR("Couldn't fetch admins");
    }
  };

export const setAllCMandSaleReps = (
  data: Array<IGetEachUserObject>,
  value: string
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_ALL_CATEGORY_MANAGERS_AND_SALESREPS,
  payload: { data, value },
});

export const getCategoryManagerAndSalesRepThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    const promise = getCmAndSaleRepDataApi();
    const response = await apiResponseHandler<
      Record<string, Array<IGetEachUserObject>>
    >(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        let categoryManagersData: Array<IGetEachUserObject> = [];
        let salesRepresentativesData: Array<IGetEachUserObject> = [];
        let designersData: Array<IGetEachUserObject> = [];
        let buHeadData: Array<IGetEachUserObject> = [];

        Object.keys(response.data).forEach(key => {
          if (KEYS_CONTAINING_CATEGORY_MANAGER.includes(key)) {
            categoryManagersData = [
              ...categoryManagersData,
              ...response.data[key],
            ];
          } else if (KEYS_CONTAINING_SALES_REPRESENTATIVE.includes(key)) {
            salesRepresentativesData = [
              ...salesRepresentativesData,
              ...response.data[key],
            ];
          } else if (KEYS_CONTAINING_DESIGNER.includes(key)) {
            designersData = [...designersData, ...response.data[key]];
          } else if (KEYS_CONTAINING_BU_HEAD.includes(key)) {
            buHeadData = [...buHeadData, ...response.data[key]];
          }
        });

        salesRepresentativesData = Array.from(
          new Set(salesRepresentativesData.map(item => JSON.stringify(item)))
        ).map(item => JSON.parse(item));

        dispatch(
          setAllCMandSaleReps(categoryManagersData, 'categoryManagersData')
        );
        dispatch(
          setAllCMandSaleReps(
            salesRepresentativesData,
            'salesRepresentativesData'
          )
        );
        dispatch(setAllCMandSaleReps(designersData, 'designersData'));
        dispatch(setAllCMandSaleReps(buHeadData, 'buHeadData'));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const getAllShareableCatalogsThunk =
  (pageNumber: number) =>
  async (
    dispatch: (action: IReduxActionType) => void,
    getState: () => IRootState
  ) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await getAllSharedCatalogsApi(pageNumber);
      if (response.status) {
        dispatch(setShareableCatalogsCount(response.data.count));
        const { results } = response.data;
        const admins = getState().catalog.admins;

        let sharedCatalogsWithFullNames: Array<ISharedCatalog> = [];

        sharedCatalogsWithFullNames = results.map(
          (sharedCatalog: Record<string, any>) => {
            const categories = sharedCatalog.category as Array<string>;
            const joinedCategoryString = categories.join(', ');
            let modifiedSharedCatalog = {
              ...sharedCatalog,
              category: joinedCategoryString,
              added_by: DEFAULT_VALUE,
              products: [],
            };

            admins.forEach(admin => {
              if (sharedCatalog.added_by === admin.id) {
                modifiedSharedCatalog.added_by = `${admin.first_name} ${admin.last_name}`;
              }
            });
            modifiedSharedCatalog.products = sharedCatalog.products_data.map(
              (eachProduct: Record<string, any>) => eachProduct.product_id
            );
            return modifiedSharedCatalog;
          }
        );

        dispatch(
          getAllShareableCatalogs(sharedCatalogsWithFullNames, {
            operator: OperatorEnum.Append,
          })
        );
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const setLoaderStatus = (payload: any): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_LOADER_STATUS,
  payload,
});

export const updateSharedCatalogProductsThunk =
  (
    data: {
      products: Array<string>;
      operator: string;
    },
    linkIdentifier: string,
    callback: () => void
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await updateSharedCatalogProducts(data, linkIdentifier);
      if (response.status) {
        Toast.SUCCESS('Catalog Products Updated!');
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
      callback();
    }
  };

export const updateSharedCatalogDetailsThunk =
  (
    data: Record<string, string>,
    products: Array<string>,
    callback: (sharedCatalogUrl: string) => void
  ) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    if (!data.title) {
      Toast.ERROR('Please enter a title');
      return;
    }
    dispatch(setLoaderStatus(true));
    try {
      let payload = {
        catalog_details: {
          title: data.title,
          description: data.description,
          image_url: data.imageLink,
        },
        products,
      };
      const response = await generateShareableLink(payload);
      if (response.status) {
        Toast.SUCCESS('Catalog Details Created!');
        callback(response.data.url);
      } else {
        Toast.ERROR(response.msg);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const toggleTrendingModal = (isOpen: boolean) => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_TRENDING_MODAL,
  payload: isOpen,
});

export const changeTrendingCategoryLoader = (payload: boolean) => ({
  type: CATALOG_ACTION_TYPES.CHANGE_TRENDING_CATEGORY_LOADER,
  payload,
});

export const changeTrendingCategoryThunk =
  (categoryId: string) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    try {
      dispatch(changeTrendingCategoryLoader(true));
      const response = await getTrendingProductsForCategoryApi(categoryId);
      const { title = '', products_data: productsData = [] } =
        response.data.length > 0 ? response.data[0] : {};
      dispatch(changeTrendingCategoryLoader(false));
      dispatch(setTrendingCategoryTitle(title));
      const productIdsMap = new Map<String, Record<string, string>>();
      productsData.forEach((eachProduct: Record<string, string>) => {
        const productData = {
          name: eachProduct.product_id,
          id: eachProduct.product_id,
        };
        productIdsMap.set(eachProduct.product_id, productData);
      });
      dispatch(setEnteredTagsForCategory(Array.from(productIdsMap.values())));
    } catch (error) {
      dispatch(changeTrendingCategoryLoader(false));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const submitTreningCategoryDataThunk =
  (data: any, categoryId: string) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    if (!categoryId) {
      Toast.WARN('Please add a category');
      return;
    }
    if (!data.title) {
      Toast.WARN('Please add a title');
      return;
    }
    try {
      dispatch(submitTrendingCategoryDataLoader(true));
      const response = await putTrendingProductsForCategoryDataApi(
        data,
        categoryId
      );
      dispatch(submitTrendingCategoryDataLoader(false));
      if (!response.status) {
        Toast.WARN(response.msg);
      } else {
        Toast.SUCCESS(response.msg);
      }
    } catch (error) {
      dispatch(submitTrendingCategoryDataLoader(false));
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const submitTrendingCategoryDataLoader = (payload: boolean) => ({
  type: CATALOG_ACTION_TYPES.SUBMIT_TRENDING_CATEGORY_DATA_LOADER,
  payload,
});

export const setTrendingCategoryTitle = (trendingCategoryTitle: string) => ({
  type: CATALOG_ACTION_TYPES.SET_TRENDING_CATEGORY_TITLE,
  payload: trendingCategoryTitle,
});

export const setEnteredTagsForCategory = (trendingTags: Array<any>) => ({
  type: CATALOG_ACTION_TYPES.SET_ENTERED_TAGS_FOR_CATEGORY,
  payload: trendingTags,
});

export const deleteEnteredTagForCategory = (trendingTag: any) => ({
  type: CATALOG_ACTION_TYPES.DELETE_ENTERED_TAG_FOR_CATEGORY,
  payload: trendingTag,
});

export const setSelectedTrendingCategoryData = (
  trendingCategory: Record<string, string>
) => ({
  type: CATALOG_ACTION_TYPES.SET_SELECTED_TRENDING_CATEGORY_DATA,
  payload: trendingCategory,
});

export const collectionPaginationInfo = (data: Record<string, number>) => ({
  type: CATALOG_ACTION_TYPES.COLLECTION_PAGINATION_INFO,
  payload: data,
});

export const setCollectionData = (
  data: Array<ICollection>,
  mode: OperatorEnum
) => ({
  type: CATALOG_ACTION_TYPES.SET_COLLECTION_DATA,
  payload: { data, mode },
});

export const getCollectionsDataThunk =
  (pageNumber: number, mode: OperatorEnum, filterParams: string = '') =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(setLoaderStatus(true));
    try {
      const response = await getAllCollectionsApi(pageNumber, filterParams);
      const { data } = response;
      const { count = 0, total_pages: totalPages = 0 } = data;
      const results: Array<ICollectionResult> = data?.results ?? [];

      dispatch(
        collectionPaginationInfo({
          count: count,
          totalPages: totalPages,
          currentPage: pageNumber,
        })
      );
      const modifiedResults = results.map(eachResult => ({
        id: eachResult.id,
        description: eachResult.description,
        title: eachResult.title,
        linkIdentifier: eachResult.link_identifier,
        imageUrl: eachResult.image_url,
        url: eachResult.url,
        brandIds: eachResult.brand_ids,
        productsData: eachResult.products_data.map(
          eachProduct => eachProduct.product_id
        ),
        createdOn: eachResult.created_on,
        addedBy: eachResult.added_by,
      }));
      dispatch(setCollectionData(modifiedResults, mode));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setLoaderStatus(false));
    }
  };

export const setCollectionUrl = (url: string) => ({
  type: CATALOG_ACTION_TYPES.SET_COLLECTION_URL,
  payload: url,
});

export const setSaveCollectionLoader = (isLoading: boolean) => ({
  type: CATALOG_ACTION_TYPES.SET_SAVE_COLLECTION_LOADER,
  payload: isLoading,
});

export const setCollectionSaveSucess = (isSucess: boolean) => ({
  type: CATALOG_ACTION_TYPES.SET_COLLECTION_SAVE_SUCCESS,
  payload: isSucess,
});

export const createCollectionFromSharedCatalog =
  (data: Record<string, any>, currentProductIds: Array<string>) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    if (!data.title) {
      Toast.INFO('Please add a title');
      return;
    }
    if (data.brand_ids.length === 0) {
      Toast.INFO('Please add atleast one brand');
      return;
    }
    if (currentProductIds.length === 0) {
      Toast.INFO('Please add atleast one product');
      return;
    }

    dispatch(setSaveCollectionLoader(true));
    try {
      const response = await createCollectionFromSharedCatalogApi(data);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(setCollectionUrl(response.data.url));
      dispatch(setCollectionSaveSucess(true));
      Toast.SUCCESS(response.msg);
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setSaveCollectionLoader(false));
    }
  };

export const deleteCollectionThunk =
  (data: Record<string, Array<string>>) =>
  async (dispatch: (action: ThunkActionType) => void) => {
    try {
      const response = await deleteCollectionApi(data);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(getCollectionsDataThunk(1, OperatorEnum.Modify));
      Toast.SUCCESS(response.msg);
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const updateCollection = (data: Record<string, any>) => ({
  type: CATALOG_ACTION_TYPES.UPDATE_COLLECTION,
  payload: data,
});

export const editCollectionThunk =
  (data: Record<string, any>, currentProductIds: Array<string>) =>
  async (dispatch: (action: ThunkActionType | IReduxActionType) => void) => {
    if (!data.title) {
      Toast.INFO('Please add a title');
      return;
    }
    if (data.brand_ids.length === 0) {
      Toast.INFO('Please add atleast one brand');
      return;
    }
    if (currentProductIds.length === 0) {
      Toast.INFO('Please add atleast one product');
      return;
    }

    dispatch(setSaveCollectionLoader(true));
    try {
      const response = await updateCollectionApi(data);
      if (!response.status) {
        Toast.ERROR(response.msg);
        return;
      }
      dispatch(updateCollection(data));
      Toast.SUCCESS(response.msg);
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    } finally {
      dispatch(setSaveCollectionLoader(false));
    }
  };

export const fileDownloadHandlerThunk =
  (selectedProductIds: Set<string>) => async () => {
    const data = {
      product_ids: Array.from(selectedProductIds),
    };

    const promise = bulkEditExcelDownloadApi(data);
    try {
      const response = await apiResponseHandler<{ sheet_url: string }>(promise);
      if (response.type === ApiResponseEnum.Success) {
        downloadFromLink({
          name: response.data.sheet_url,
          link: response.data.sheet_url,
        });
        Toast.SUCCESS('File downloaded');
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const allProductDownloadHandlerThunk =
  (queryString: string, catalogId: string) =>
  async (dispatch: (action: ThunkActionType | IReduxActionType) => void) => {
    dispatch(fetchDownloadLoader(true));
    const promise = getAllAdminCatalogProductsApi(
      queryString,
      MAXIMUM_PAGE_SIZE,
      catalogId
    );
    try {
      const response = await apiResponseHandler(promise);
      if (response.type === ApiResponseEnum.Success) {
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(fetchDownloadLoader(false));
  };

const setLocationRegion = (
  allLocationRegionData: Array<IGetLocationData>
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_LOCATION_REGION_DATA,
  payload: allLocationRegionData,
});

export const fetchAllLocationRegionThunk =
  () => async (dispatch: (action: IReduxActionType) => void) => {
    const promise = getLocationRegionDataApi();
    const response = await apiResponseHandler<IGetResponse>(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        dispatch(setLocationRegion(response.data.results));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const updateSelectedProductsLocation = (
  selectedRegion: Array<number>
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.SET_SELECTED_PRODUCTS_LOCATION,
  payload: selectedRegion,
});

export const updateLocationRegionData =
  (data: ISendLocationData) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    const promise = updateLocationRegionDataApi(data);
    const response = await apiResponseHandler(promise);
    try {
      if (response.type === ApiResponseEnum.Success) {
        dispatch(updateSelectedProductsLocation(data.region_ids));
        Toast.SUCCESS(response.msg);
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  };

export const fetchQrCodeLoader = (payload: boolean): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.FETCH_QR_CODE_LOADER,
  payload,
});

export const getQrCodeThunk =
  (data: ISendQrCode) =>
  async (dispatch: (action: ThunkActionType | IReduxActionType) => void) => {
    dispatch(fetchQrCodeLoader(true));

    try {
      const promise = getQrCodeApi(data);
      const response = await apiResponseHandler<IGetQrCodeResponse>(promise);
      if (response.type === ApiResponseEnum.Success) {
        Toast.SUCCESS(response.msg);
        downloadFromLink({
          name: response.data.pdf_url,
          link: response.data.pdf_url,
        });
        dispatch(removeProductIds([...data.product_ids]));
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(fetchQrCodeLoader(false));
  };

export const toggleSaveImage = (payload: boolean): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.TOGGLE_SAVE_IMAGE_LOADER,
  payload,
});

export const updateEachProductImages = (
  selectedId: number,
  updatedImage: Array<IImage>
): IReduxActionType => ({
  type: CATALOG_ACTION_TYPES.UPDATE_IMAGES_IN_ORIGINAL_LIST,
  payload: { selectedId, updatedImage },
});

export const updateImageThunk =
  (data: IUplaodImageType, onClose: () => void) =>
  async (dispatch: (action: IReduxActionType) => void) => {
    dispatch(toggleSaveImage(true));
    const promise = updateImagesApi(data);
    try {
      const response = await apiResponseHandler<IGetEachProductDetails>(
        promise
      );
      if (response.type === ApiResponseEnum.Success) {
        const { id, images } = response.data;
        dispatch(updateEachProductImages(id, images));
        Toast.SUCCESS(response.msg);
        onClose();
      } else {
        Toast.ERROR(response.error);
      }
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
    dispatch(toggleSaveImage(false));
  };
