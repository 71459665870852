import { useDispatch } from 'react-redux';
import StyledButton, { StyledButtonVariant } from 'components/StyledButton';
import { uploadCatalogueSheetIdle } from 'actions/catalog';
import ICONOGRAPHY from 'CustomConstant/icons';
import { ERROR_LIST } from './constants';
import * as Styles from './styles';

const { 'excel-upload-failure': ExcelUploadFailure } = ICONOGRAPHY;

interface IUploadFailure {
  error: string;
  filename: string;
}

function errorToBeShown(error: string) {
  let newError = error;
  ERROR_LIST.map(err => {
    if (error.includes(err.error)) {
      newError = err.replacement;
    }
    return err;
  });
  return newError;
}

const UploadFailed = ({ filename, error }: IUploadFailure) => {
  const dispatch = useDispatch();
  const errorToShow = errorToBeShown(error);

  return (
    <Styles.Wrapper>
      <ExcelUploadFailure />
      <Styles.FileName>{filename}</Styles.FileName>
      <Styles.FailureReason>
        <pre>{errorToShow}</pre>
      </Styles.FailureReason>
      <Styles.ButtonContainer>
        <StyledButton
          variant={StyledButtonVariant.Link}
          style={{ padding: '12px 130px', marginTop: '8px' }}
          onClick={() => {
            dispatch(uploadCatalogueSheetIdle());
          }}
        >
          Go Back
        </StyledButton>
      </Styles.ButtonContainer>
    </Styles.Wrapper>
  );
};

export default UploadFailed;
