import styled, { keyframes } from 'styled-components';

interface IProgressBar {
  completion: number;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 77px;
  width: auto;
  max-width: 40vw;
  margin: 0 auto;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  .excel-upload-retry {
    padding: 12px 140px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: var(--watermelon);
    border: 1px solid var(--watermelon);
    border-radius: 4px;
  }
`;

export const FileName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--manatee);
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const FailureReason = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--red-dirt);
  margin-bottom: 8px;
  max-width: inherit;
  overflow-x: auto;
`;

export const FailureMessage = styled.div`
  width: 410px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: var(--manatee);
  margin-bottom: 32px;
`;

const progressBarAnimation = keyframes`
  0% {width: 0%;}
  30% {width: 30%;}
  70% {width: 70%;}
`;

export const ProgressBar = styled.div`
  width: 320px;
  height: 8px;
  background-color: var(--athens-gray);
  border-radius: 8px;
`;

export const ProgressBarFill = styled.div<IProgressBar>`
  height: 8px;
  width: ${props => props.completion}%;
  background-color: var(--ocean-green);
  //   animation: ${progressBarAnimation} 2s linear 1;
  border-radius: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
